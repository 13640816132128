@font-face {
  font-family: "Satoshi Variable";
  font-style: normal;
  font-weight: 300 900;
  src: local("Satoshi Variable"), local("Satoshi-Variable"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Inter Regular"), local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

body {
  overflow: hidden;
  height: 100vh;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
  font-family: "Inter Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
