@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

[data-amplify-authenticator] {
  --amplify-components-button-primary-hover-background-color: #0f4241;
  --amplify-components-button-primary-background-color: #00994f;
  --amplify-components-tabs-item-active-color: #0f4241;
  --amplify-components-tabs-item-active-border-color: #00994f;
  --amplify-components-textfield-border-color: #dcdee0;
  --amplify-components-button-border-color: #dcdee0;
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-router-border-radius: 5px;
}

.app-dark [data-amplify-authenticator] {
  --amplify-components-textfield-color: white;
  --amplify-components-input-color: white;
  --amplify-components-tabs-item-active-color: #00994f;
  --amplify-components-tabs-item-focus-color: #00994f;
  --amplify-components-tabs-item-color: #777;
  --amplify-components-authenticator-state-inactive-background-color: #222;
  --amplify-components-authenticator-router-background-color: #333;
  --amplify-colors-background-secondary: #555;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  border-radius: 5px 0 0 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.AuthContainer-header {
  padding: var(--amplify-components-authenticator-form-padding);
  padding-bottom: 0;
}

.PageContent-heroImage {
  background-repeat: no-repeat;
  background-size: cover;
  /* width: inherit; */
  height: 100%;
  flex-grow: 2;
  flex-basis: 400px;
}

.PageContent-heroImage-graphic {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 550px;
  width: 550px;
  bottom: -150px;
}

.PageContent-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: white; */
  overflow: auto;
}

.PageContent-content {
  /* padding: 40px 96px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 500px;
  overflow: auto;
  z-index: 0;
}

.PageContent-content-noPad {
  padding: 0;
}

.PageContent-body {
  position: relative;
  margin: auto;
}

.DashboardContainer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Dashboard-decorationImage {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 550px;
  width: 550px;
  bottom: -150px;
  right: 5%;
}

.Dashboard-card {
  width: 1320px;
  margin-top: 32px;
  padding: 24px;
}

.Error-card {
  padding: 80px;
  height: 100%;
}

.LinkingAccount-message {
  width: 460px;
  display: flex;
  flex-direction: row;
}

.flex-spacer {
  flex: 1;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
}

.flex-col-container {
  display: flex;
  flex-direction: column;
}

.flex-centered {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.Docs-header {
  margin-top: 4em;
  margin-bottom: 2em;
  max-width: 860px;
}

.Docs-card {
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  transition: transform 0.16s ease-out, background-color 0.16s ease-out,
    border-color 0.16s ease-out;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.1);
}

.Docs-card:hover {
  transform: scale(1.01);
  transition: transform 0.16s ease-out, background-color 0.16s ease-out,
    border-color 0.16s ease-out;
  border-color: #00994f;
}

.Docs-card-link {
  text-decoration: unset !important;
  cursor: pointer !important;
  width: 100%;
}

.Docs-tee-icon {
  width: 86px;
  margin-right: 16px;
}

.MuiTableCell-sizeSmall {
  padding: 6px !important;
}

.eula li {
  font-size: 14px;
}

.eula p {
  margin: 8px 0;
}

/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track-piece {
} */

/* Scrollbar style for light theme */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Scrollbar style for dark theme */
@media (prefers-color-scheme: dark) {
  .app-dark ::-webkit-scrollbar {
    width: 8px;
    background-color: #333;
  }
  .app-dark ::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
}

/* Scrollbar style for light theme (when forced by user) */
@media (prefers-color-scheme: light) {
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #e8e8e8;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #666;
  }
}

.ReactMarkdown p {
  margin-top: 0;
  margin-block-start: 0.5em;
}

.app-dark .ReactMarkdown h1,
.app-dark .ReactMarkdown h2,
.app-dark .ReactMarkdown h3,
.app-dark .ReactMarkdown h4 {
  font-family: "Satoshi Variable";
  color: white;
}

.app-dark .ReactMarkdown a {
  color: #00994f;
}

.app-light .ReactMarkdown h1,
.app-light .ReactMarkdown h2,
.app-light .ReactMarkdown h3,
.app-light .ReactMarkdown h4 {
  font-family: "Satoshi Variable";
  color: #4a4a4a;
}

.ReactMarkdown h1 {
  font-size: 32px;
  font-weight: 600;
}

.ReactMarkdown h1 {
  font-size: 32px;
  font-weight: 600;
}

.ReactMarkdown h2 {
  font-size: 22px;
}

.ReactMarkdown h3 {
  font-size: 20px;
  margin: 12px 0;
}

.editable-markdown-container > div {
  width: 100%;
}

[contenteditable] {
  outline: 0px solid transparent;
  background: rgba(255, 255, 255, 0.1);
}

* {
  backface-visibility: hidden;
}

/* Scrollable table body with fixed header - https://stackoverflow.com/a/29512692 */

.MuiTable-root {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.MuiTable-table thead,
.MuiTableHead-root {
  /* head takes the height it requires, 
  and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}
.MuiTable-table tbody,
.MuiTableBody-root {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
}
.MuiTable-table tbody,
.MuiTableBody-root tr,
.MuiTableRow-root {
  width: 100%;
}
.MuiTable-table thead,
.MuiTable-table tbody,
MuiTableBody-root tr,
.MuiTableRow-root {
  display: table;
  table-layout: fixed;
}
